




import Vue from "vue"

export default Vue.extend({
  name: "TAStream",
  components: {
    TwitchPlayer: () => import("./TwitchPlayer.vue")
  },
  props: {
    streamUrl: {
      type: String,
      required: false
    }
  }
})
